import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';

import PolicyTemplate from '../../templates/Policy';

const Policy = ({ data, pageContext }) => {
  const {
    markdownRemark: { frontmatter, html }
  } = data;
  const { language } = pageContext;
  const { pageTitle, ...pageContent } = frontmatter;

  return (
    <Layout language={language} pageTitle={pageTitle} activePage="/">
      <PolicyTemplate {...{ ...pageContent, html }} />
    </Layout>
  );
};

Policy.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        header: PropTypes.object.isRequired
      }).isRequired,
      html: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default Policy;
