import { graphql } from 'gatsby';

import Policy from './template';

export const pageQuery = graphql`
  query Policy($language: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "Policy" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        pageTitle
        header {
          title
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 6500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      html
    }
  }
`;

export default Policy;
