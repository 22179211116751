import React from 'react';
import PropTypes from 'prop-types';

import HeaderSection from '../../components/HeaderSection';

const PolicyTemplate = ({ html, header }) => (
  <>
    <HeaderSection {...header} />
    <section className="container mx-auto px-4 pb-16 pt-8">
      <div
        className="md:markdown-holder markdown-holder-sm break-words"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </section>
  </>
);

PolicyTemplate.propTypes = {
  header: PropTypes.object.isRequired,
  html: PropTypes.string.isRequired
};

export default PolicyTemplate;
